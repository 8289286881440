import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { Form, Card, Badge, CloseButton, Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import DateTimePickerModal from '../../components/campaign/DateTimePickerModal'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function DndCanvasNodeEditor(props) {
    const { settings } = props
    // const { node, draggables, options, setNode, prev, handleDeleteNode, dateTimePickerModalParams} = settings
    const { node, draggables, options, setNode, prev, handleDeleteNode, handleCloseNodeEditing} = settings
    // const { scheduleModalShow, time, onTimeChange, onCloseClick, onSubmitClick, handleScheduleModalShow } = dateTimePickerModalParams
    const { t, i18n } = useTranslation('DndCanvasNodeEditor', 'DndCanvas', 'common')

    const booleanDeterminationOptions = [
        {label: 'open', value: 'open'},
        {label: 'click', value: 'click'},
    ]

    const waitPeriodHoursOptions = [
        {label: '0', value: '0'},
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'},
        {label: '5', value: '5'},
        {label: '6', value: '6'},
        {label: '7', value: '7'},
        {label: '8', value: '8'},
        {label: '9', value: '9'},
        {label: '10', value: '10'},
        {label: '11', value: '11'},
        {label: '12', value: '12'},
        {label: '13', value: '13'},
        {label: '14', value: '14'},
        {label: '15', value: '15'},
        {label: '16', value: '16'},
        {label: '17', value: '17'},
        {label: '18', value: '18'},
        {label: '19', value: '19'},
        {label: '20', value: '20'},
        {label: '21', value: '21'},
        {label: '22', value: '22'},
        {label: '23', value: '23'},
    ]
    const waitPeriodDaysOptions = [
        {label: '0', value: '0'},
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'},
        {label: '5', value: '5'},
        {label: '6', value: '6'},
    ]
    const waitPeriodWeeksOptions = [
        {label: '0', value: '0'},
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'},
    ]
    const dire_name = prev.map((p) => {
        if (p.prevAction ? !p.prevAction.action : !p.data.campaign_name) return p.data.directory?.dire_name || ''
        return p.prevAction
            ?   p.prevAction.bool
                ?   p.data.campaign_name + '_' + `${p.prevAction.action}`
                :   p.data.campaign_name + '_' + `un${p.prevAction.action}`
            :   p.data.directory.dire_name
    })

    // Functions
    const [emailTemplates, setEmailTemplates] = useState([])
    const getEmailTemplates = async () => {
        const response = await options.emailTemplates()
        setEmailTemplates(response.data.templates)
    }

    const [emailDirectories, setEmailDirectories] = useState([])
    const getEmailDirectories = async () => {
        const response = await options.emailDirectories()
        setEmailDirectories(response.data.data)
    }

    const [workflowDirectories, setWorkflowDirectories] = useState([])
    const getWorkflowDirectories = async () => {
        const response = await options.workflowDirectories()
        setWorkflowDirectories(response.data.data)
    }

    const [smsTemplates, setSmsTemplates] = useState([])
    const getSmsTemplates = async () => {
        const response = await options.smsTemplates()
        setSmsTemplates(response.data)
    }

    const [directories, setDirectories] = useState([])
    const getDirectories = async () => {
        const response = await options.directories()
        setDirectories(response.data.data)
    }

    const [emailSettingPairs, setEmailSettingPairs] = useState([])
    const getEmailSettingPairs = async () => {
        const response = await options.emailSettingPairs()
        setEmailSettingPairs(response.data.data)
    }


    useEffect(() => {
        // 依據節點選擇欲呼叫之資料
        switch (node.type) {
            case 'directories':
                // getDirectories()
                getWorkflowDirectories()
                break

            case 'smsCampaign':
                getSmsTemplates()
                break

            case 'emailCampaign':
                getEmailTemplates()
                getEmailSettingPairs()
                break

            default:
                break
        }
    }, [node])

    const initScheduleTime = useMemo(() => {
        const datetime_now = new Date()
        return new Date(datetime_now.setHours(datetime_now.getHours() + parseInt(15 * Math.ceil(datetime_now.getMinutes() /15)/60),(15 * Math.ceil(datetime_now.getMinutes() /15)) % 60, 0))
    }, [])

    const [scheduleModal, setScheduleModal] = useState({
        scheduleTime: initScheduleTime,
        scheduleModalShow: false,
    });

    // 如果不放在useEffect裡，在user切換node時，scheduleTime會異常
    useEffect(() => {
        const getScheduleTime = () => {
            if (node.label === 'email_campaign') {
                return node.data.sendtime ? new Date(node.data.sendtime) : initScheduleTime
            } else if (node.label === 'sms_campaign') {
                return node.data.sendtime ? moment(node.data.sendtime, 'YYYYMMDDHHmmss').toDate() : initScheduleTime
            } else {
                return initScheduleTime
            }
        }
        setScheduleModal(prevState => ({
            ...prevState,
            scheduleTime: getScheduleTime()
        }))
    }, [node.data.sendtime, node.label, initScheduleTime])

    const handleModalClose = () => {
        setScheduleModal(prevState => ({
            ...prevState,
            // 關閉modal時不要重設，以免把user設定的時間清掉
            // scheduleTime: new Date(initScheduleTime),
            scheduleModalShow: false,
        }))
    }
    const handleScheduleModalSubmit = () => {
        setScheduleModal(prevState => ({
            ...prevState,
            scheduleModalShow: false,
        }))
        const format = {
            smsCampaign: 'yyyyMMDDHHmm00',          // sendtime: modal.scheduleTime.getFullYear().toString() + (modal.scheduleTime.getMonth() + 1).toString().padStart(2, "0") + modal.scheduleTime.getDate().toString().padStart(2, "0") + modal.scheduleTime.getHours().toString().padStart(2, "0") + modal.scheduleTime.getMinutes().toString().padStart(2, "0") + "00"
            emailCampaign: 'yyyy-MM-DD HH:mm:00',   // schedule_time: modal.scheduleTime.getFullYear().toString() +"-"+ (modal.scheduleTime.getMonth() + 1).toString().padStart(2, "0") +"-"+  modal.scheduleTime.getDate().toString().padStart(2, "0") + " " + modal.scheduleTime.getHours().toString().padStart(2, "0") + ":" + modal.scheduleTime.getMinutes().toString().padStart(2, "0") + ":00",
        }
        setNode({ 
            ...node, 
            data: {
                ...node.data, 
                sendtime: moment(scheduleModal.scheduleTime).format(format[node.type])
            } 
        })
    }

    const handleScheduleModalShow = () => {
        setScheduleModal(prevState => ({
            ...prevState,
            scheduleModalShow: true,
        }))
    }
    const onModalChange = (event, action) => {
        if (action !== undefined) {
            if (action.type === "click") {
                let newScheduleDate = new Date(scheduleModal.scheduleTime).setFullYear(event.getFullYear(), event.getMonth(), event.getDate())
                setScheduleModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleDate)
                }))
            } else if (action.name === "time-hour") {
                let newScheduleHour = new Date(scheduleModal.scheduleTime).setHours(event.value)
                setScheduleModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleHour)
                }))
            } else if (action.name === "time-minute") {
                let newScheduleMinute = new Date(scheduleModal.scheduleTime).setMinutes(event.value)
                setScheduleModal(prevState => ({
                    ...prevState,
                    "scheduleTime": new Date(newScheduleMinute)
                }))
            }
        } else {
            setScheduleModal(prevState => ({
                ...prevState,
                "data": {
                    ...prevState.data,
                    [event.target.name]: event.target.value
                }
            }))
        }
    }
    // console.log('DndCanvasNodeEditor ST: ', scheduleModal.scheduleTime)

    switch (node.type) {
        case 'directories':
            return (
                <div className='h-100 d-flex flex-column'>
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('directories', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        // placeholder="Choose the directory group..."
                        closeMenuOnSelect={true}
                        name="workflow-directories"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ ...node, data: {...node.data, directory: {'dire_name': event.label, 'dire_id': event.value}} })}
                        value={{ label: node.data.directory.dire_name, value: node.data.directory.dire_id }}
                        // options={directories.map(s => ({ label: s.dire_name, value: s.dire_id }))}
                        options={workflowDirectories.map(s => ({ label: s.dire_name, value: s.dire_id }))}
                        // value={{ label: node.data.template.template_name, value: node.data.template.template_id }}
                    />
                    <hr />
                    <Row>
                        <Col xs={6}>
                            <Button className='w-100' variant="primary" onClick={() => handleCloseNodeEditing()}>{t('confirm', { ns: 'common'})}</Button>
                        </Col>
                        <Col xs={6}>
                            <Button className='w-100' variant="danger" onClick={() => handleDeleteNode(node.id)}>{t('delete', { ns: 'common'})}</Button>
                        </Col>
                    </Row>
                </div>
            )

        case 'emailCampaign': 
            return (
                <>
                    <div className='h-100 d-flex flex-column overflow-scroll'>
                        <Form.Label className="h7 text-orca mt-1 mb-0">{t('campaign_name', { ns: 'DndCanvas'})}</Form.Label>
                        <Form.Control
                            maxLength="40" 
                            name="workflow-emailCampaignName" 
                            type="text" 
                            placeholder={t('campaign_name', { ns: 'DndCanvas'})} 
                            defaultValue={node.data.title} 
                            onChange={(event) => setNode({ ...node, data: {...node.data, title: event.target.value} })} 
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('subject_line', { ns: 'DndCanvas'})}</Form.Label>
                        <Form.Control
                            maxLength="40" 
                            name="workflow-emailSubjectLine" 
                            type="text" 
                            placeholder={t('subject_line', { ns: 'DndCanvas'})} 
                            defaultValue={node.data.subject_line} 
                            onChange={(event) => setNode({ ...node, data: {...node.data, subject_line: event.target.value} })} 
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('preview_text', { ns: 'DndCanvas'})}</Form.Label>
                        <Form.Control 
                            maxLength="40" 
                            name="workflow-emailPreviewText" 
                            type="text" 
                            placeholder={t('preview_text', { ns: 'DndCanvas'})} 
                            defaultValue={node.data.preview_text} 
                            onChange={(event) => setNode({ ...node, data: {...node.data, preview_text: event.target.value} })} 
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('template', { ns: 'DndCanvas'})}</Form.Label>
                        <Select
                            styles={purpleSelector}
                            // placeholder="Choose the directory group..."
                            closeMenuOnSelect={true}
                            name="workflow-emailTemplates"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            onChange={(event) => setNode({ ...node, data: {...node.data, template: {template_name: event.label, template_id: event.value}} })}
                            options={emailTemplates.map(s => ({ label: s.template_name, value: s.template_id }))}
                            value={{ label: node.data.template.template_name, value: node.data.template.template_id }}
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('directory', { ns: 'DndCanvas'})}</Form.Label>
                        <Form.Control
                            maxLength="40" 
                            name="workflow-emailDirectory" 
                            type="text" 
                            defaultValue={dire_name} 
                            disabled
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('sender_and_reply_to', { ns: 'DndCanvas'})}</Form.Label>
                        <Select
                            styles={purpleSelector}
                            // placeholder="Choose the directory group..."
                            closeMenuOnSelect={true}
                            name="workflow-emailSenders"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            onChange={(event) => setNode({ ...node, data: {...node.data, sender: {sender: event.label, reply_to: event.value}} })}
                            options={emailSettingPairs.map(s => ({ value: s.reply_to, label: s.name }))}
                            value={{ label: node.data.sender.sender, value: node.data.sender.reply_to }}
                        />
                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('sendtime', { ns: 'DndCanvas'})}</Form.Label>                
                        <div className='d-flex'>
                            <Form.Control
                                maxLength="40" 
                                name="workflow-emailSendtime" 
                                type="text" 
                                // defaultValue={scheduleModal.scheduleTime} 
                                value={scheduleModal.scheduleTime}
                                disabled
                            />
                            <Button variant='orca' onClick={() => handleScheduleModalShow()}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Button>
                        </div>
                        <hr />
                        <Row>
                            <Col xs={6}>
                                <Button className='w-100' variant="primary" onClick={() => handleCloseNodeEditing()}>{t('confirm', { ns: 'common'})}</Button>
                            </Col>
                            <Col xs={6}>
                                <Button className='w-100' variant="danger" onClick={() => handleDeleteNode(node.id)}>{t('delete', { ns: 'common'})}</Button>
                            </Col>
                        </Row>
                    </div>
                    <DateTimePickerModal settings={{
                        submitButton: {
                            name: 'workflowCampaignSchedule',
                            value: '-',
                            content: "Submit",
                            loaded: true,
                        },
                        scheduleModalShow: scheduleModal.scheduleModalShow,
                        time: scheduleModal.scheduleTime,
                        onTimeChange: onModalChange,
                        onCloseClick: handleModalClose,
                        onSubmitClick: handleScheduleModalSubmit,
                        noPrev: true,
                    }}
                    />
                </>
            )
        case 'smsCampaign': 
            return (
                <>
                    <div className='h-100 d-flex flex-column overflow-scroll'>
                        <Form.Label className="h7 text-orca mt-1 mb-0">{t('活動名稱', { ns: 'DndCanvas'})}</Form.Label>
                        <Form.Control 
                            maxLength="40" 
                            name="workflow-smsCampaignName" 
                            type="text" 
                            placeholder={t('campaign_name', { ns: 'DndCanvas'})} 
                            defaultValue={node.data.campaign_name} 
                            onChange={(event) => setNode({ 
                                ...node, 
                                data: {
                                    ...node.data, 
                                    campaign_name: event.target.value
                                }
                            })} 
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('template', { ns: 'DndCanvas'})}</Form.Label>
                        <Select
                            maxMenuHeight={190}
                            styles={purpleSelector}
                            // placeholder="Choose the directory group..."
                            closeMenuOnSelect={true}
                            name="workflow-smsTemplates"
                            components={{ Option: TitledOptions, Control: TitledControl }}
                            onChange={(event) => {
                                setNode({ ...node, data: {...node.data, template: {subject: event.label, sms_id: event.value}} })
                            }}
                            options={smsTemplates.map(s => ({ label: s.subject, value: s.sms_id }))}
                            value={{ label: node.data.template.subject, value: node.data.template.sms_id }}
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('directories', { ns: 'DndCanvas'})}</Form.Label>
                        <Form.Control
                            maxLength="40" 
                            name="workflow-smsDirectory" 
                            type="text" 
                            defaultValue={dire_name} 
                            disabled
                        />

                        <Form.Label className="h7 text-orca mt-2 mb-0">{t('sendtime', { ns: 'DndCanvas'})}</Form.Label>
                        <div className='d-flex'>
                            <Form.Control 
                                maxLength="40" 
                                name="workflow-smsDirectory" 
                                type="text" 
                                // defaultValue={scheduleModal.scheduleTime} 
                                value={scheduleModal.scheduleTime}
                                disabled
                            />
                            <Button variant='orca' onClick={() => handleScheduleModalShow()}>
                                <FontAwesomeIcon icon={faCalendarAlt} />
                            </Button>
                        </div>
                        <hr />
                        <Row>
                            <Col xs={6}>
                                <Button className='w-100' variant="primary" onClick={() => handleCloseNodeEditing()}>{t('confirm', { ns: 'common'})}</Button>
                            </Col>
                            <Col xs={6}>
                                <Button className='w-100' variant="danger" onClick={() => handleDeleteNode(node.id)}>{t('delete', { ns: 'common'})}</Button>
                            </Col>
                        </Row>
                    </div>

                    <DateTimePickerModal settings={{
                        submitButton: {
                            name: 'workflowCampaignSchedule',
                            value: '-',
                            content: "Submit",
                            loaded: true,
                        },
                        scheduleModalShow: scheduleModal.scheduleModalShow,
                        time: scheduleModal.scheduleTime,
                        onTimeChange: onModalChange,
                        onCloseClick: handleModalClose,
                        onSubmitClick: handleScheduleModalSubmit,
                        noPrev: true,
                    }}
                    />
                </>
            )
        case 'booleanDetermination':
            return (
                <div className='h-100 d-flex flex-column'>
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('action', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-booleanDeterminationAction"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ ...node, data: {...node.data, action: event.value }})}
                        options={booleanDeterminationOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.action, value: node.data.action }}
                    />

                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('wait_period_weeks', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-waitPeriodWeeks"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ 
                            ...node, 
                            data: {
                                ...node.data, 
                                wait: {
                                    ...node.data.wait,
                                    weeks: event.value,
                                }
                            }
                        })}
                        options={waitPeriodWeeksOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.wait.weeks, value: node.data.wait.weeks }}
                    />
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('wait_period_days', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-waitPeriodDays"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ 
                            ...node, 
                            data: {
                                ...node.data, 
                                wait: {
                                    ...node.data.wait,
                                    days: event.value,
                                }
                            }
                        })}
                        options={waitPeriodDaysOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.wait.days, value: node.data.wait.days }}
                    />
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('wait_period_hours', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-waitPeriodHours"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ 
                            ...node, 
                            data: {
                                ...node.data, 
                                wait: {
                                    ...node.data.wait,
                                    hours: event.value,
                                }
                            }
                        })}
                        options={waitPeriodHoursOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.wait.hours, value: node.data.wait.hours }}
                    />
                    <hr />
                    <Row>
                        <Col xs={6}>
                            <Button className='w-100' variant="primary" onClick={() => handleCloseNodeEditing()}>{t('confirm', { ns: 'common'})}</Button>
                        </Col>
                        <Col xs={6}>
                            <Button className='w-100' variant="danger" onClick={() => handleDeleteNode(node.id)}>{t('delete', { ns: 'common'})}</Button>
                        </Col>
                    </Row>

                </div>
            )
        case 'wait':
            return (
                <div className='h-100 d-flex flex-column'>
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('wait_period_weeks', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-waitPeriodWeeks"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ 
                            ...node, 
                            data: {
                                ...node.data, 
                                wait: {
                                    ...node.data.wait,
                                    weeks: event.value,
                                }
                            }
                        })}
                        options={waitPeriodWeeksOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.wait.weeks, value: node.data.wait.weeks }}
                    />
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('wait_period_days', { ns: 'DndCanvas'})}</Form.Label>
                    <Select 
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-waitPeriodDays"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ 
                            ...node, 
                            data: {
                                ...node.data, 
                                wait: {
                                    ...node.data.wait,
                                    days: event.value,
                                }
                            }
                        })}
                        options={waitPeriodDaysOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.wait.days, value: node.data.wait.days }}
                    />
                    <Form.Label className="h7 text-orca mt-1 mb-0">{t('wait_period_hours', { ns: 'DndCanvas'})}</Form.Label>
                    <Select
                        styles={purpleSelector}
                        closeMenuOnSelect={true}
                        name="workflow-waitPeriodHours"
                        components={{ Option: TitledOptions, Control: TitledControl }}
                        onChange={(event) => setNode({ 
                            ...node, 
                            data: {
                                ...node.data, 
                                wait: {
                                    ...node.data.wait,
                                    hours: event.value,
                                }
                            }
                        })}
                        options={waitPeriodHoursOptions.map(s => ({ label: s.label, value: s.value }))}
                        value={{ label: node.data.wait.hours, value: node.data.wait.hours }}
                    />
                    <hr />
                    <Row>
                        <Col xs={6}>
                            <Button className='w-100' variant="primary" onClick={() => handleCloseNodeEditing()}>{t('confirm', { ns: 'common'})}</Button>
                        </Col>
                        <Col xs={6}>
                            <Button className='w-100' variant="danger" onClick={() => handleDeleteNode(node.id)}>{t('delete', { ns: 'common'})}</Button>
                        </Col>
                    </Row>
                </div>
            )
        default:
            return (<></>)
    }

}
